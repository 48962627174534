import { ChangeEvent, FC, useContext } from 'react';
import { reach } from 'yup';

import AppContext from '/component/Apps/App/AppContext';

import { AppNameStyled } from './styles';

const AppNameComponent: FC = () => {
  const { form, readonly, onUpdateValue, validationSchema } = useContext(AppContext);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateValue('name', event.target.value);
  };

  return (
    <AppNameStyled
      schema={reach(validationSchema, 'name')}
      required={true}
      disabled={readonly}
      label='Application name'
      inputProps={{ 'data-testid': 'input-name' }}
      value={form.name}
      onChange={handleNameChange}
      variant='outlined'
    />
  );
};

export const AppName = AppNameComponent;
