import { FC, MouseEvent } from 'react';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { IFingerprintSaltsValid } from './types';
import { AppSaltsButton, AppSaltsInfoIcon } from './styles';

interface IAppSaltsLabelProps {
  openSalts: boolean;
  setOpenSalts: () => void;
  fingerprintSaltsValid: IFingerprintSaltsValid;
  readonly: boolean;
}

const AppSaltsLabel: FC<IAppSaltsLabelProps> = (props) => {
  const { openSalts, setOpenSalts, fingerprintSaltsValid, readonly } = props;

  const invalidFingerprintSaltsEnv =
    (fingerprintSaltsValid.sandbox && 'sandbox') || (fingerprintSaltsValid.live && 'live');

  const handleOpenSalts = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!readonly) {
      setOpenSalts();
    }
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {invalidFingerprintSaltsEnv && (
        <Tooltip
          placement='top'
          title={`Salts mismatch Fingerprint clientSideSecret for ${invalidFingerprintSaltsEnv} environment.`}
        >
          <AppSaltsInfoIcon color='error' />
        </Tooltip>
      )}
      <b>Salts</b>
      <AppSaltsButton color='inherit' disabled={readonly} onClick={handleOpenSalts} size='large'>
        {openSalts ? <LockOpenIcon /> : <LockIcon />}
      </AppSaltsButton>
    </>
  );
};

export default AppSaltsLabel;
