import { List, styled, Theme } from '@mui/material';

import { BlockThemed } from '/shared/BlockThemed';

export const AppMarketsStyled = styled(BlockThemed)`
  grid-column: 2 / span 2;
`;

export const AppMarketsList = styled(List)<{ theme: Theme }>`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;
