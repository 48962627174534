import { FC, useContext } from 'react';

import { LabelThemedColor } from '/shared/LabelThemed';
import AppContext from '/component/Apps/App/AppContext';
import { snackbarService } from '/common/snackbarService';

import { AppLabelStyled } from './styles';

const AppLabelsContent: FC = () => {
  const { app, onCopyLabel } = useContext(AppContext);

  const handleClick = (value: string | number) => () => {
    if (typeof value === 'string') {
      snackbarService.info(`${app.name} FingerprintID copied to clipboard!`);
    } else {
      snackbarService.info(`${app.name} ID copied to clipboard!`);
    }
    onCopyLabel(value);
  };

  const shouldShowAppID = app.id !== undefined && app.id.id > 0;
  const shouldHideFingerprintID = !app.id?.id;

  return (
    <div>
      {shouldShowAppID && (
        <AppLabelStyled
          data-testid='app-id-label'
          color={LabelThemedColor.Grey}
          clickable={true}
          onClick={handleClick(app.id.id)}
        >
          AppID: {app.id.id}
        </AppLabelStyled>
      )}
      {!shouldHideFingerprintID && (
        <AppLabelStyled color={LabelThemedColor.Grey} clickable={true} onClick={handleClick(app.fingerprintID.app)}>
          FingerprintID: {app.fingerprintID.app}
        </AppLabelStyled>
      )}
    </div>
  );
};

export const AppLabels = AppLabelsContent;
