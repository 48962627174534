import { useCallback, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { QueryScriptRuntimeResponse, ScriptRuntime } from '@playq/octopus2-scripts';
import { AppID } from '@playq/octopus-common';

import { scriptsQueryRuntimeKeys, useScriptsRuntimeQuery } from '/api/hooks/scriptsService';
import { snackbarService } from '/common/snackbarService';

import { useScriptContext } from './useScriptContext';
import {
  IScriptMethodsData,
  IUseScriptsMetadataResult,
  IInvokeScriptsHookParams,
  IScriptHooksFunction,
  IScriptMethod,
  IScriptMethodsFunction,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
const AsyncFunction = (Object.getPrototypeOf(async function () {}) as Function).constructor as FunctionConstructor;

export function useScriptsMetadata(
  utilities?: {
    log?: (message: string) => void;
    popup?: (message: string) => Promise<void>;
    prompt?: (message: string) => Promise<boolean>;
  },
  trustCache = false
): IUseScriptsMetadataResult {
  const context = useScriptContext(utilities);

  const queryClient = useQueryClient();
  const cached = queryClient.getQueryData(scriptsQueryRuntimeKeys.concat((context.appID as AppID)?.toString?.()));

  const {
    data: runtimeData,
    refetch: refetchRuntimes,
    error,
  } = useScriptsRuntimeQuery(context.appID, {
    cacheTime: 10 * 60 * 1000,
    enabled: !trustCache || cached === undefined,
  });

  const ongoing = useRef<{ [key: string]: number }>({});
  const [, setRefresh] = useState({});

  const ready: boolean = Boolean(runtimeData) && !error;

  const getScriptsNames = useCallback(() => {
    if (!runtimeData) {
      return [];
    }

    return runtimeData.runtimes.map((r) => r.name);
  }, [runtimeData]);

  const getScriptsMethods = useCallback((): IScriptMethodsData[] => {
    if (!runtimeData) {
      return [];
    }

    return runtimeData.runtimes.map((r) => ({
      id: r.id.serialize(),
      name: r.name,
      methods: r.methods,
      hooks: r.hooks,
      runtime: r.runtimeContent.runtime,
    }));
  }, [runtimeData]);

  const getScriptMethods = useCallback(
    (scriptID: string) => {
      if (!runtimeData) {
        return [];
      }

      return runtimeData.runtimes.find((s: ScriptRuntime) => s.id.serialize() === scriptID)?.methods || [];
    },
    [runtimeData]
  );

  const getScriptHooks = useCallback(
    (scriptID: string) => {
      if (!runtimeData) {
        return [];
      }

      return runtimeData.runtimes.find((s: ScriptRuntime) => s.id.serialize() === scriptID)?.hooks || [];
    },
    [runtimeData]
  );

  const addRunner = useCallback((scriptRuntimeName: string, functionName: string) => {
    const key = `${scriptRuntimeName}.${functionName}`;
    if (key in ongoing.current) {
      const message = `Script ${scriptRuntimeName}.${functionName} is already running`;
      snackbarService.warning(message);
      console.warn(message);
      return false;
    }

    ongoing.current[key] = Date.now();
    setRefresh({});

    return () => {
      delete ongoing.current[key];
      setRefresh({});
    };
  }, []);

  const invokeScriptsMethod = useCallback(
    async (scriptRuntimeID: string, methodName: string, mocks?: QueryScriptRuntimeResponse) => {
      const dataRuntimes = mocks?.runtimes || (runtimeData?.runtimes as ScriptRuntime[]);
      const id = `'${scriptRuntimeID}.${methodName}'`;

      if (!ready && !mocks) {
        const message = `Scripts are not yet ready for method execution ${id}`;
        snackbarService.warning(message);
        console.warn(message);
        return false;
      }

      const scriptRuntime: ScriptRuntime | undefined = dataRuntimes.find(
        (runtime: ScriptRuntime) => runtime.id.serialize() === scriptRuntimeID
      );

      if (!scriptRuntime) {
        const message = `No script found with name ${id}`;
        snackbarService.warning(message);
        console.warn(message);
        return false;
      }

      if (scriptRuntime.methods.findIndex((m) => m === methodName) < 0) {
        const message = `No script method found with name ${id}`;
        snackbarService.warning(message);
        console.warn(message);
        return false;
      }

      const finish = addRunner(scriptRuntime.name, methodName);

      if (finish === false) {
        return false;
      }

      let methodResult: boolean | undefined | void = true;

      try {
        const runtimeFuncBody = `"use strict";\n${scriptRuntime.runtimeContent.runtime}`;
        const runtimeFunc = new AsyncFunction(runtimeFuncBody) as IScriptMethodsFunction;
        const targetMethod: IScriptMethod = (await runtimeFunc())[methodName];

        methodResult = targetMethod(context);
      } catch (err) {
        const message = `Error happened while executing script method ${id}`;
        snackbarService.error(`${message} ${err as string}`);
        console.error(message, err);
        methodResult = false;
      }

      finish();

      return methodResult;
    },
    [context, runtimeData?.runtimes, ready, addRunner]
  );

  const invokeScriptsHook = useCallback(
    async <TData>(...[hookName, ...args]: IInvokeScriptsHookParams) => {
      if (context.appID === undefined) {
        return true;
      }

      if (!ready) {
        const message = `Scripts are not yet ready for hook execution ${hookName}`;
        snackbarService.warning(message);
        console.warn(message);

        return false;
      }

      const finish: false | VoidFunction = addRunner('hooks', hookName);

      if (finish === false) {
        return false;
      }

      let result: TData | boolean = true;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      for (const runtimeInstance of runtimeData!.runtimes) {
        // can runtimeData be undefined?
        // TODO Optimize this one, maybe pre-bake scripts before we try to execute them instead of
        // ad hoc at the time of execution.
        try {
          const runtimeFunc = new AsyncFunction(
            `"use strict";\n${runtimeInstance.runtimeContent.runtime}`
          ) as IScriptHooksFunction;
          const targetHook = (await runtimeFunc())[hookName] as IScriptHooksFunction | undefined;

          if (!targetHook) {
            continue;
          }

          const hookResult = await (targetHook as (...hookArgs: unknown[]) => Promise<TData>)(context, ...args);
          if (hookResult === false) {
            result = false;

            break;
          }
          result = hookResult ?? true;
        } catch (err) {
          const message = `Error happened while executing script hook ${runtimeInstance.id.id}/${runtimeInstance.name}.${hookName}`;
          snackbarService.error(`${message} ${err as string}`);
          console.error(message, err);
          result = false;

          break;
        }
      }

      finish();

      return result;
    },
    [context, runtimeData, ready, addRunner]
  );

  return {
    runtimes: runtimeData?.runtimes ?? [],
    areScriptsReady: ready,
    ongoing,
    working: Object.keys(ongoing.current).length > 0 ? true : false,
    refetchRuntimes,
    invokeScriptsMethod,
    invokeScriptsHook,
    getScriptsNames,
    getScriptsMethods,
    getScriptHooks,
    getScriptMethods,
  };
}
