import { IconButton, styled, Theme } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

import { BlockThemed } from '/shared/BlockThemed';

export const AppSaltsStyled = styled(BlockThemed)<{ theme: Theme }>`
  & > div {
    margin-top: ${({ theme }) => theme.spacing(2)};
    display: grid;
    row-gap: ${({ theme }) => theme.spacing(3)};
  }

  & > label {
    display: flex;
    align-items: center;
  }
`;

export const AppSaltsButton = styled(IconButton)`
  padding: 5px;
`;

export const AppSaltsInfoIcon = styled(InfoIcon)`
  margin: 0 2px;
`;
