import { ChangeEvent, FC, useContext, useState } from 'react';
import { reach, Schema } from 'yup';

import { Environment } from '@playq/octopus-common';

import AppContext from '/component/Apps/App/AppContext';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';

import { IFingerprintSaltsValid } from './types';
import AppSaltsLabel from './AppSaltsLabel';
import AppSalt from './AppSalt';
import { AppSaltsStyled } from './styles';

const AppSaltsComponent: FC = () => {
  const { app, form, readonly, onUpdateValue, validationSchema } = useContext(AppContext);

  const [openSalts, setOpenSalts] = useState(false);

  const isFingerprintSaltInvalid = (env: 'sandbox' | 'live') =>
    app.fingerprintSalts?.[env] && app.fingerprintSalts[env]?.clientSideSecret !== form.salts[env];

  const fingerprintSaltsValid: IFingerprintSaltsValid = {
    sandbox: isFingerprintSaltInvalid('sandbox'),
    live: isFingerprintSaltInvalid('live'),
  };

  const handleOpenSalts = () => {
    if (!openSalts) {
      confirmDialog({
        title: `EDIT Salts?`,
        text: `Changing app salts may cause to mismatch on Fingerprint clientSideSecret. Continue with editing?`,
        type: ConfirmDialogType.Warning,
        closeButton: { label: 'NO' },
        successButton: { label: 'YES' },
        onSuccess: () => setOpenSalts(!openSalts),
      });

      return;
    }

    setOpenSalts(!openSalts);
  };

  const handleChangeSalts = (salt: string) => (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateValue('salts', { ...form.salts, [salt]: event.target.value });
  };

  return (
    <AppSaltsStyled
      label={
        <AppSaltsLabel
          openSalts={openSalts}
          setOpenSalts={handleOpenSalts}
          fingerprintSaltsValid={fingerprintSaltsValid}
          readonly={readonly}
        />
      }
    >
      <AppSalt
        env={Environment.Sandbox}
        validationSchema={reach(validationSchema, 'salts.sandbox') as unknown as Schema<string>}
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        salt={form.salts ? form.salts.sandbox : ''}
        disabled={!openSalts || readonly}
        changeSalt={handleChangeSalts('sandbox')}
      />
      <AppSalt
        env={Environment.Live}
        validationSchema={reach(validationSchema, 'salts.live') as unknown as Schema<string>}
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        salt={form.salts ? form.salts.live : ''}
        disabled={!openSalts || readonly}
        changeSalt={handleChangeSalts('live')}
      />
    </AppSaltsStyled>
  );
};

export const AppSalts = AppSaltsComponent;
