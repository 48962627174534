import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { App, AppFeature, AppName, FileIconSource, IconSource } from '@playq/octopus2-apps';
import { MarketImage, MarketImagesResponse, MarketInfo } from '@playq/octopus2-markets';
import { FileRevisionID } from '@playq/octopus2-files';
import { PermModule } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useAppDispatch, appToolkit, authToolkit } from '/store';
import { selectApp } from '/store/toolkits/app/actions';
import { history } from '/Router/history';
import { clipboardWrite, pushIdToURL, throwGenericFailure } from '/helpers';
import { snackbarService } from '/common/snackbarService';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';
import {
  APP_CREATION_HOOK_NAME,
  APP_SAVE_HOOK_NAME,
  appsQueryTags,
  useAppCreateScripted,
  useAppSaveScripted,
  useAppsQuery,
  useAppUpdate,
} from '/api';
import { relativeCreateURL } from '/constants';
import { useStateChange } from '/common/useStateChange';
import { useScriptsMetadata } from '/common/scripts';

import { AppImage, IAppContext, IAppForm, IAppProviderProps, IAppState } from './types';
import { getValidationSchema, marketValidationSchema } from './schemas';
import { backURL, emptyApp, emptyAppState, maxQuery } from './constants';
import { goToApps } from './helpers';

// @ts-expect-error Argument of type '{}' is not assignable to parameter of type 'IAppContext'.
const AppContext = createContext<IAppContext>({});

export const AppProvider: FC<IAppProviderProps> = ({
  appName: propsAppName,
  readonly,
  children,
  currentPageQueryKey,
}) => {
  const company = useSelector(authToolkit.selectors.company);
  const selectedApp = useSelector(appToolkit.selectors.app);
  const { canWrite, canWriteLive } = useSelector(appToolkit.selectors.moduleAccess(PermModule.ApplicationsManagement));

  const dispatch = useAppDispatch();
  const updateSelectedApp = useCallback((a: App) => dispatch(appToolkit.actions.updateApp(a.serialize())), [dispatch]);

  const defaultState = useMemo(
    () => ({ ...emptyAppState, readonly: (!canWrite && !canWriteLive) || !!readonly }),
    [readonly, canWrite, canWriteLive]
  );

  const [initialState, setInitialState] = useState<IAppState>(defaultState);
  const [state, setState] = useState<IAppState>(defaultState);
  const [app, setApp] = useState(new App());
  const [enableAppsQuery, setEnableAppsQuery] = useState<boolean>(true);
  const [appName, setAppName] = useState<string | undefined>(propsAppName);

  const isCreating: boolean = appName === relativeCreateURL;

  const { appImage, selectedFile, marketImages } = state;
  const updateState = useCallback((key: keyof IAppState, value: unknown, isInitial = false) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
    if (isInitial) {
      setInitialState((prevState) => ({ ...prevState, [key]: value }));
    }
  }, []);
  const setAppImage = useCallback(
    (nImg?: AppImage, isInitial = false) => {
      updateState('appImage', nImg, isInitial);
    },
    [updateState]
  );
  const setSelectedFile = useCallback(
    (nFile?: FileRevisionID, isInitial = false) => {
      updateState('selectedFile', nFile, isInitial);
    },
    [updateState]
  );

  const noChanges = useStateChange({ state, initialState });

  const { apps } = useAppsQuery(maxQuery.iterator, maxQuery.sortBy, maxQuery.filterBy, {
    enabled: enableAppsQuery,
    onSuccess: () => {
      setEnableAppsQuery(false);
    },
  });

  const serializedAppID = app?.id?.serialize();

  const validationSchema = useMemo(() => {
    const existingNames = apps?.reduce((acc: string[], cur) => {
      if (cur.id.serialize() !== serializedAppID) {
        acc.push(cur.name);
      }
      return acc;
    }, []);
    return getValidationSchema(existingNames);
  }, [apps, serializedAppID]);

  const handlerFailure = useCallback((err: Error | GenericFailure) => {
    console.error(err.message);
    snackbarService.error(`AppFetch: ${err.message}`);
    goToApps();
  }, []);

  const { invokeScriptsHook } = useScriptsMetadata();

  const { mutate: onBeforeSaveApp } = useAppSaveScripted(invokeScriptsHook);

  const { mutate: createAppWithReactQuery, isLoading: createLoading } = useAppCreateScripted(invokeScriptsHook, {
    onMutate: () => {
      setInitialState(state);
    },
    onSuccess: (a: App) => {
      setAppName(a.name);
      pushIdToURL(modAppName(a.name));
      confirmDialog({
        title: `Register ${a.name} at Fingerprint?`,
        text: `Do you want to register the ${a.name} at Fingerprint?`,
        type: ConfirmDialogType.Info,
        closeButton: { label: 'NO' },
        successButton: { label: 'YES' },
        onSuccess: () => dispatch(selectApp({ app: a })).then(() => onGoToServices(a)),
        onClose: () => initState(a, true),
      });
      snackbarService.success('Application has been created');
    },
    onError: handlerFailure,
    removeQueriesKeys: appsQueryTags,
  });
  const { mutate: updateAppWithReactQuery, isLoading: updateLoading } = useAppUpdate({
    onMutate: () => {
      setInitialState(state);
    },
    onSuccess: (a: App) => {
      snackbarService.success('Application has been updated');
      if (selectedApp && selectedApp.id.serialize() === a.id.serialize()) {
        updateSelectedApp(a);
      }
      pushIdToURL(modAppName(a.name), { replace: true });
      initState(a, true);
    },
    onError: handlerFailure,
    removeQueriesKeys: currentPageQueryKey ?? appsQueryTags,
  });

  const updateStateAfterMarketImagesFetch = useCallback(
    (res: MarketImagesResponse) => (prevState: IAppState) => {
      const images = { ...prevState.marketImages };
      res.images.forEach((marketImage: MarketImage) => {
        images[marketImage.market] = marketImage.url;
      });
      return { ...prevState, marketImages: images };
    },
    []
  );

  const getMarketImages = useCallback(
    (marketInfo: MarketInfo[], isInitial = false) => {
      services2.marketsService
        .retrieveMarketsImage(marketInfo)
        .then((data) => {
          data.bifold(
            (res: MarketImagesResponse) => {
              if (res.images.length > 0) {
                setState(updateStateAfterMarketImagesFetch(res));
                if (isInitial) {
                  setInitialState(updateStateAfterMarketImagesFetch(res));
                }
              }
            },
            (err) => throwGenericFailure(err)
          );
        })
        .catch((err: Error) => {
          console.error(err.message);
          snackbarService.error(`MarketsImageFetch: ${err.message}`);
        });
    },
    [updateStateAfterMarketImagesFetch]
  );

  const updateStateForm = useCallback(
    (formState: IAppState['form']) => (prevState: IAppState) => ({
      ...prevState,
      form: formState,
      isValid: validationSchema.isValidSync(formState),
    }),
    [validationSchema]
  );

  const modAppName = (rawAppName: string) => {
    return rawAppName
      .split(/[ _-]+/gm)
      .filter((s) => s !== '')
      .join('-')
      .toLowerCase();
  };

  const initState = useCallback(
    (a: App, isEditMode?: boolean) => {
      setApp(a);
      setAppImage(a.iconFile, isEditMode);
      setSelectedFile(a.iconFile, isEditMode);

      const formState = {
        name: a.name,
        salts: {
          sandbox: a.salts.sandbox,
          live: a.salts.live,
        },
        platforms: a.platforms,
        markets: a.markets.reduce((map: { [market: string]: string }, marketInfo: MarketInfo) => {
          map[marketInfo.market] = marketInfo.appIdentificator;
          return map;
        }, {}),
      };

      setState(updateStateForm(formState));
      setInitialState(updateStateForm(structuredClone(formState)));

      if (a.markets.length > 0) {
        getMarketImages(a.markets, isEditMode);
      }
    },
    [getMarketImages, updateStateForm, setAppImage, setSelectedFile]
  );

  const initApp = useCallback(
    (didCancel?: boolean) => {
      if (!appName) {
        goToApps();
        return;
      }

      if (isCreating) {
        initState(emptyApp);
      } else {
        const identity = new AppName();
        identity.name = modAppName(appName);
        identity.company = company ? company.id : 0;
        services2.appsService
          .retrieve(identity, [AppFeature.FingerprintSalts])
          .then((data) => {
            data.bifold(
              (a) => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (a && !didCancel) {
                  initState(a, true);
                }
              },
              (err) => throwGenericFailure(err)
            );
          })
          .catch((err: Error) => handlerFailure(err));
      }
    },
    [appName, company, handlerFailure, initState, isCreating]
  );

  useEffect(() => {
    let didCancel = false;
    initApp(didCancel);
    return () => {
      didCancel = true;
    };
  }, [appName, initApp]);

  const createAppFromState = () => {
    const markets = Object.keys(state.form.markets).map((market: string) => {
      return new MarketInfo({ market, appIdentificator: state.form.markets[market].trim() });
    });

    return new App({
      ...app.serialize(),
      ...state.form,
      markets,
      salts: {
        ...state.form.salts,
        sandbox: state.form.salts.sandbox.trim(),
        live: state.form.salts.live.trim(),
      },
      name: state.form.name.trim(),
    });
  };

  const getIconSource = (): IconSource | undefined => {
    if (!appImage) {
      return;
    }

    return appImage instanceof FileRevisionID
      ? new FileIconSource({ fileRevisionID: appImage.serialize() })
      : new MarketInfo({
          market: appImage.market,
          appIdentificator: state.form.markets[appImage.market],
        });
  };

  const onSave = () => {
    if (!state.isValid || (!canWrite && !canWriteLive) || noChanges) {
      return;
    }

    const editedApp = createAppFromState();
    const iconFile = getIconSource();

    if (isCreating) {
      createAppWithReactQuery({
        serviceMethodArgs: [editedApp, iconFile],
        scriptsResolverArgs: [APP_CREATION_HOOK_NAME, editedApp, iconFile],
      });

      return;
    }
    onBeforeSaveApp({ serviceMethodArgs: [editedApp.id], scriptsResolverArgs: [APP_SAVE_HOOK_NAME, editedApp.id] });
    updateAppWithReactQuery({ app: editedApp, iconFile });
  };

  const onCopyLabel = (label: string | number) => {
    clipboardWrite(label.toString());
  };

  const onGoToServices = (a: App) => history.push(`/apps/${a.routeName}/services/config`);

  const onUpdateValue = (key: keyof IAppForm, value: unknown) => {
    const formState = {
      ...state.form,

      [key]: value,
    };

    setState({
      ...state,
      form: formState,
      isValid: validationSchema.isValidSync(formState),
    });
  };

  const onReset = () => (isCreating ? initState(emptyApp) : initState(app));

  const onFetchMarketImage = useCallback(
    (market: string, appIdentificator?: string) => {
      if (appIdentificator && appIdentificator.length > 0) {
        const marketInfo = new MarketInfo({ market, appIdentificator });
        getMarketImages([marketInfo]);
      }
    },
    [getMarketImages]
  );

  const onSelectAppImage = (img?: AppImage) => {
    if (img && img instanceof FileRevisionID) {
      setSelectedFile(img);
    }

    setAppImage(img || selectedFile);
  };

  const contextValue: IAppContext = {
    ...state,
    app,
    appImage,
    marketImages,
    validationSchema,
    marketValidationSchema,
    isAdmin: canWrite || canWriteLive,
    pending: createLoading || updateLoading,
    backURL,
    noChanges,
    isCreating,
    onUpdateValue,
    onFetchMarketImage,
    onSelectAppImage,
    onCopyLabel,
    onSave,
    onReset,
    onGoBack: goToApps,
  };
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContext;
