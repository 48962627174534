import { ListItemButton, styled, Theme } from '@mui/material';

import { BlockThemed } from '/shared/BlockThemed';

export const AppPlatformsStyled = styled(BlockThemed)<{ theme: Theme }>`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const AppPlatformItem = styled(ListItemButton)<{ theme: Theme }>`
  display: flex;
  justify-content: space-around;
  padding: 0 0 0 ${({ theme }) => theme.spacing(1)};
  text-transform: none;
`;
