import { FC } from 'react';
import { Checkbox, ListItemText } from '@mui/material';

import { Platform } from '@playq/octopus2-apps';

import { AppPlatformItem } from './styles';

interface IAppPlatformProps {
  platform: Platform;
  selected: boolean;
  readonly: boolean;
  togglePlatform: () => void;
}

const AppPlatform: FC<IAppPlatformProps> = (props) => {
  const { platform, selected, readonly, togglePlatform } = props;

  return (
    <AppPlatformItem disabled={readonly} onClick={togglePlatform}>
      <ListItemText primary={platform} />
      <Checkbox data-testid={`${platform}-checkbox`} checked={selected} />
    </AppPlatformItem>
  );
};

export default AppPlatform;
